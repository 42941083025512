@media (min-width: 992px) {

    .modal-lg, .modal-xl {
        max-width: 1200px !important;
    }
    .text-center {
        text-align: center;
    }
}
.info-value {
    font-size: 12px !important;
}
.info-cost {
    color: #999999;
    font-size: 12px;
    margin-bottom: 3px;
}
.input-select{
    margin: 35px auto;
    display: block;
}
.row-announcement-detail {
    border-top: solid 1px #dddddd;
    padding-top: 20px;
    margin-top: 20px
}
.badge-status-announcement {
    margin-top: 20px;
}
.announcement-detail {
    margin-top: 25px;
}
.announcement-date {
    margin: 3px 0 0 0;
    font-weight: bold;
    color: #999999;
}
.announcement-value {
    margin-top: 20px;
    font-weight: bold;
    color: #999999;
}
.announcement-title {
    font-size: 14px;
    font-weight: bold;
    //color: #666666;
    margin: 3px 0;
}
.announcement-title-small {
    font-size: 14px;
    font-weight: bold;
    color: #666666;
    margin: 3px 0;
}
.announcement-price {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 3px;
}
.announcement-stock {
    font-size: 12px;
    font-weight: normal;
    color: #999999;
}
.announcement-info-value {
    color: #00ff5a
}
.btn-announcement-detail {
    margin-top: 20px
}
.input-select-announcement {
    display: inline;
    margin-right: 25px;
}
.col-image-announcement {
    max-width: 94px;
}
.announcement-info {
    color: #999999;
    font-size: 12px;
    //font-weight: bold;
    //text-align: center;
}
.announcement-info > p {
    margin-bottom: 3px;
}
.announcement-health {
    /*width: 60px;
    float: left;
    position: absolute;
    margin: 30% auto;*/
    text-align: center;
    font-weight: bold;
    color: #999999;
}
.btn-duplicate {
    margin-bottom: 5px;
}
.variation-row {
    margin-top: 15px;
}

.record-status-menu {
  list-style: none;
  display: flex;
  border-bottom: solid 1px #f4f4f4;

  li {
      cursor: pointer;
      border-bottom: solid 1px #ffffff;
      padding: 10px 25px;
      color: #999999;
      font-weight: bold;
      border: none;
  }
  li:hover {
      color: #666666
  }
  li.active {
      color: #000000;
      border-bottom: solid 1px #2fb15d;
  }
  .total-content{
    text-align: center;
  }
}
.container-btn-filter{
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 15px;
  margin-bottom: 15px;
}
i:hover {
  transform: inherit;
  box-shadow: none;
}

.button-container {
  vertical-align: middle;
  display: flex;
  justify-content: center;
}

.btn-success .fa-check {
  background-color: transparent;
}

.btn-success:hover, .btn-danger:hover {
  opacity: 0.8;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-bordered {
  border: none;
}

.table-bordered th,
.table-bordered td {
  border: none;
  border-top: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
  border-top: none;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
