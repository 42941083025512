// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui";

// If you want to add something do it here
@import "custom";

@import "../app/views/announcement/announcement.component.scss";

@font-face {
  font-family: "quicksand";
  src: url("../assets/fonts/Quicksand-Regular.ttf") format("truetype");
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'quicksand', 'arial', sans-serif;
}


.tab-content {
  margin-top: -1px;
  background: #fff;
  border: none;
}

.tab-content .tab-pane {
    padding: 1rem;
    padding-left: 0;
    padding-right: 0;
}

.nav-tabs {
  border-bottom: 2px solid #e7ecf1;
  //background: #f0f3f5;
}

.nav-tabs {
  .nav-link {
    color: #999999;
    font-weight: bold;
    //background-color: #f0f3f5;
    border: none;
    &:hover {
      cursor: pointer;
      color: #333333;
      border: none;
      border-radius: unset;
      font-weight: bold;
    }
    &.active {
      font-weight: bold;
      border-bottom: solid 2px #2fb15d;
      border-right: none;
      border-left: none;
      border-radius: unset;
      &:focus {
        background: #fff;
        //border-color: #2fb15d;
        border-bottom-color: #2fb15d;
      }
    }
  }
}

a {
  color: #2fb15d;
}

.form-control:focus {
  //color: #5c6873;
  background-color: #fff;
  border: none;
  /* border-color: #2fb15d; */
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(47 177 93 / 48%);
}

.btn {
  font-weight: bold;
}

*::placeholder, input::-webkit-input-placeholder {
  color: #c2c9ce !important
}

.modal-footer {
  border-top: 1px solid #f4f4f4;
}
.modal-header {
  border-bottom: 1px solid #f4f4f4;
}