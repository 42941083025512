// Here you can add other styles
.main .container-fluid {
    height: 93%;
    background-color: #ffffff;
    padding: 0px 15px 15px 30px !important;
}
.main .container-fluid-full {
    padding: 0 !important;
}
.app-header .navbar-brand {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 155px;
    height: 55px;
    padding: 0;
    margin-right: 0;
    margin-left: 20px;
    background-color: transparent;
}
.main {
    margin-top: 0;
}
.sidebar-minimized .sidebar .nav > .nav-dropdown:hover > .nav-dropdown-items {
    position: absolute;
    left: 65px;
    display: inline;
}
@media (min-width: 992px){
    html:not([dir=rtl]) .sidebar-lg-show.sidebar-fixed .main, html:not([dir=rtl]) .sidebar-lg-show.sidebar-fixed .app-footer, html:not([dir=rtl]) .sidebar-show.sidebar-fixed .main, html:not([dir=rtl]) .sidebar-show.sidebar-fixed .app-footer {
        margin-left: 230px;
        background: #ffffff;
    }
}
.x8-icon-md {
    color: #2fb15d;
    font-size: 25px;
}
button.dropdown-item,
.bs-datepicker-head button,
.btn-outline-primary,
.btn-outline-danger {
    box-shadow: none;
}
.dropdown-item:hover, .dropdown-item:focus, .dropdown-item:active {
    color: #333333;
    text-decoration: none;
    background-color: #f0f3f5;
}
.dropdown-item.active, .dropdown-item:active {
    color: #333333;
    text-decoration: none;
    background-color: #f0f3f5;
}
.bg-primary{
    background-color: #2fb15d !important;
}
.bg-secondary, .bg-secondary div.card-header, .bg-secondary div.card-footer {
    background-color: #f2f3f8 !important;
    border-color: #e1e1e4 !important;
}
.btn-success {
    color: #ffffff;
    background-color: #2fb15d;//#2fb15d;
    border-color: #2fb15d;
}
.btn-success:hover {
    color: #222222;
    background-color: #00ff5a;
    border-color: #00ff5a;
}
.btn-shadow {
    border: solid 1px #f4f4f4;
}
.btn-shadow:hover {
    background-color: transparent;
    border: solid 1px transparent;
    box-shadow: 2px 2px 5px #dddddd;
}
.btn-utility {
    color: #2fb15d;
    background-color: #f0f3f5;
    border-color: #f2f3f8;
    font-weight: bold;
    cursor: pointer;
}
.btn-utility:hover {
    background: #2fb15d;
    color: #ffffff;
}
.btn-utility-warning {
    color: #ff9900;
    background-color: #ecedf1;
    border-color: #f2f3f8;
}
.btn-ghost-utility {
    color: #2fb15d;
}
.card.bg-primary {
    border-color: #26b396;
}
.card.bg-warning {
    border-color: #ffc107;
}
.card.bg-danger {
    border-color: #f86c6b;
}
.card.bg-info {
    border-color: #63c2de;
}
.card.bg-success {
    border-color: #4dbd74;
}
.btn-info {
    color: #ffffff;
}
/*.btn-primary {
    color: #fff;
    background-color: #222222;
    border-color: #222222;
}*/
.btn-ghost-primary:hover {
    color: #fff;
    background-color: #333333;
    border-color: #333333;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #666666;
    border-color: #666666;
}
.btn-link {
    font-weight: 400;
    color: #073256;
    text-decoration: none;
}
.brand-minimized .app-header .navbar-brand {
    width: 155px;
    background-color: transparent;
}
.sidebar .sidebar-minimizer:hover {
    background-color: #f2f3f8;
    color: #999999 !important;
}
.sidebar-minimized .sidebar .sidebar-minimizer {
    position: fixed;
    bottom: 0;
    width: 65px;
    height: 50px;
    background-color: #ffffff;
}
.sidebar-minimized .sidebar .nav-link .nav-icon {
    display: block;
    float: left;
    width: 65px;
    font-size: 18px;
}
.sidebar-minimized .sidebar .nav-item {
    width: 65px;
    overflow: hidden;
}
.sidebar-minimized.sidebar-fixed .sidebar {
    width: 65px;
}
.sidebar .nav-link:hover {
    color: #dddfe8;
    background: #099c7e;
}
.sidebar-minimized .sidebar .nav-link:hover {
    width: 315px;
    background: #dddfe8 !important;
}
.sidebar-minimized .sidebar .nav-item:hover > .nav-link {
    background: #caccd4;
}
.breadcrumb-item {
    a {
        color: #999999;
        font-weight: normal;
    }
}
.breadcrumb-item.active {
    color: #2fb15d;
}
#div-logo{
    margin-left: 10px;
    margin-top: 50px;
    height: 90px;
}
/*.btn-primary:hover {
    color: #fff;
    background-color: #527680;
    border-color: #527680;
}*/
.main .container-fluid {
    padding: 0 15px 15px 30px;
}
.sidebar-minimized .sidebar .nav > .nav-dropdown:hover {
    background: #cccccc;
    box-shadow: 3px 3px 10px #dddddd;
}
.sidebar-minimized .sidebar .nav-item:hover {
    width: 315px;
    overflow: visible;
}
.sidebar-minimized .sidebar .nav-dropdown-items .nav-item .nav-link {
    width: 250px;
}
.sidebar-minimized .sidebar .nav-dropdown-items .nav-item .nav-link >
.sidebar-minimized .sidebar .nav-item:hover > .nav-link .nav-icon {
    width: 30px !important;
    color: #fff;
}
.sidebar {
    display: flex;
    flex-direction: column;
    padding: 0;
    color: #999999;
    background: #ffffff;
    border-right: solid 1px #e9eaee;
    width: 265px;
    //padding-top: 10px;
    //box-shadow: 3px 3px 10px #dddfe8;
    font-size: 13px;
}
.sidebar .nav {
    width: 240px;
    flex-direction: column;
    min-height: 100%;
    padding: 0;
    margin-top: 5px;
}
.sidebar .nav-item {
    color: #b7b7b7;
}
.sidebar .nav-link .nav-icon {
    display: inline-block;
    width: 1.09375rem;
    //margin: 0 1rem 0 0rem;
    font-size: 1.3rem;
    text-align: center;
    margin: 0 1.2rem 0 0.5rem;
    //color: #2fb15d;
    color: #b7b7b7
}
.sidebar .nav-link.active {
    color: #222222;
    background: transparent;
    font-weight: bold;
    width: 240px;
}
.sidebar .nav-link.active .nav-icon{
    //color: #2fb15d;
    color: #333333;
}
.sidebar .nav-link {
    display: block;
    padding: 1rem 1rem;
    color: #999999;
    text-decoration: none;
    background: transparent;
    font-size: 0.925rem;
}
.sidebar .sidebar-nav {
    position: relative;
    flex: 1;
    width: 240px;
}
.sidebar .nav-link:hover {
    color: #333333 !important;
    font-weight: bold;
    background-color: #dddfe8;
    width: 240px;
}
.sidebar .sidebar-minimizer{
    background-color: #ffffff
}
.sidebar .nav-title{
    color: #cccccc
}
.sidebar-fixed .sidebar {
    width: 240px;
}
.navbar-nav .open > .nav-link, .navbar-nav .open > .nav-link:hover, .navbar-nav .open > .nav-link:focus, .navbar-nav .active > .nav-link, .navbar-nav .active > .nav-link:hover, .navbar-nav .active > .nav-link:focus, .navbar-nav .nav-link.open, .navbar-nav .nav-link.open:hover, .navbar-nav .nav-link.open:focus, .navbar-nav .nav-link.active, .navbar-nav .nav-link.active:hover, .navbar-nav .nav-link.active:focus {
    color: #cccccc;
}
.app-header{
    background-color: #ffffff;
    //box-shadow: 0px 3px 5px #cccccc;
    border: none
}
.app-header .nav-item .nav-link {
    padding-top: 0;
    padding-bottom: 0;
    background: 0;
    border: 0;
    color: #999999;
    font-size: 18px;
}
.app-body, .app-footer{
    background-color: #ffffff;
}
.app-footer a{
    color: #222222
}
.navbar-nav .nav-link:hover, .navbar-nav .nav-link:focus {
    color: #2fb15d;
}
.light-gray-text{
    color: #999999;
    font-size: 12px;
}
#loading-content{
    width: 160px;
    text-align: center;
    margin: auto;
}
#loading-content img {
    width: 80px;
    display: block;
    margin: auto;
}
#loading-content div.modal-content{
    border: none;
    background-color: transparent;
}
.triangle-up {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid rgb(173, 25, 25);
    margin: auto;
  }
  .triangle-down {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid #2fb15d;
    margin: auto;
  }
.invalid-input{
    display: block;
    width: 100%;
    margin: 0.80rem 0;
    font-size: 80%;
    color: #f86c6b;
}
.box-more-filters {
    margin-top: 10px;
}
#card-forgotpass{
    height: 322px !important;
}
.div-badge-platform{
    height: 20px;
}
.dropdown-menu {
    box-shadow: 0 5px 15px #cccccc;
    border: 1px solid #f2f3f8 !important;
}
.dropdown-item {
    border-bottom: 1px solid #f2f3f8 !important;
}
.dropdown-item .badge{
    position: relative;
    margin-left: 10px;
}
.app-header {
    height: 60px;
    border-bottom: solid 1px #f4f4f4;
}
.app-header .navbar-toggler {
    display: none !important;
}
.app-header .navbar-nav {
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
}
.app-header .nav-item {
    position: relative;
    min-width: 50px;
    margin: 0px 12px;
    text-align: center;
}
.bubble-message-left{
    background-color: #dddddd;
    font-size: 12px;
    color: #666666;
    border-radius: 0px 25px 25px;
    padding: 10px 15px 2px 15px;
}
.bubble-message-right{
    background-color: #65d4bd;
    font-size: 12px;
    color: #333333;
    border-radius: 25px 25px 0px;
    padding: 10px 15px 2px 15px;
}
.bar-message-day{
    background-color: #f2f3f8;
    text-align: center;
    color: #999999;
    font-size: 11px;
    margin-bottom: 10px
}
.div-info-message{
    text-align: right
}
#input-message{
    margin-top: 30px
}
.item-price {
    font-size: 14px;
    margin: 5px 0;
    text-align: right;
}
.item-quantity {
    text-align: right;
}
.debibt-value{
    color: red
}
.info-label {
    color: #999999;
    margin-bottom: 0;
    font-size: 12px;
}
.yield-label {
    color: #2fb15d;
    font-size: 14px;
    font-weight: bold;
}
.value-label-positive {
    color: #2fb15d !important;
    font-size: 14px;
    font-weight: bold;
}
.yield-label-negative {
    color: rgb(173, 25, 25) !important;
}
.yield-value-label {
    color: #999999;
    margin-bottom: 0;
}
.yield-value-label-total {
    color: #999999;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
}
.badge {
    border-radius: 10rem !important;
    padding-right: 0.6em;
    padding-left: 0.6em;
}
.badge-mercadolivre{
    background-color: #fee635 !important
}
.badge-magazineluiza{
    background-color: #0484fb !important;
    color: #ffffff
}
.x8-background{
    //background-image: url('../assets/img/background.jpeg');
    background-color: #ffffff;
}
.order-date{
    color: #999999;
    margin-top: 15px;
}
.order-price{
    font-size: 14px
}
.breadcrumb {
    position: relative;
    border-radius: 0;
    border: none;
    background-color: #ffffff;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: bold;
    padding: 15px 35px;
}
.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #ffffff !important;
    border-bottom: 1px solid #e9e9ec;
    //color: #999999;
}
.sidebar .nav-link .nav-icon:hover{
    //color: #2fb15d !important
    color: #999999 !important
}
.sidebar-minimized .sidebar .nav > .nav-dropdown > .nav-dropdown-items {
    display: none;
    max-height: 1000px;
    background: #f2f3f8;
    box-shadow: 3px 3px 10px #dddddd;
}
.sidebar .nav-link:hover .nav-icon {
    color: #333333;
}
.card{
    border-color: #f2f3f8;//#e9e9ec;
    margin-bottom: 1rem;
}
.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #ffffff;
    border-bottom: 1px solid #e9e9ec;
}
.card-footer {
    background-color: #ffffff;
    border-top: 1px solid #e9e9ec;
}
.img-avatar-company{
    max-width: 100%;
    height: 40px !important;
    border-radius: 20px;
}
#card-orders{
    min-height: 120px
}
.order-detail{
    color: #666666;
}
.badge-price-order{
    font-size: 14px
}
.card-order-detail{
    margin-bottom: 15px
}
#payment-order-detail{
    min-height: 161px
}
#shipping-order-detail{
    min-height: 134px
}
#items-order-detail{
    min-height: 296px
}
.title-order-detail{
    color: #999999
}
.info-value {
    color: #999999;
    font-size: 14px;
    font-weight: bold;
}
.negative-value{
    color: red
}
.positive-value{
    color: #1db954
}
.button-filter-form{
    margin-top: 28px
}
.btn-ghost-info:hover {
    color: #ffffff;
    background-color: #63c2de;
    border-color: #63c2de;
}
.spotlight-card{
    border-color: #cccccc;
    font-size: 12px
}
.bg-default{
    background-color: #cccccc
}
.card-hidden-order-detail{
    margin-top: 10px;
    border: none;
}
.card-hidden-order-detail div.card-footer{
    border-top: none;
}
.card-gray{
    background-color: #dddddd;
}
.row-order-package{
    margin-bottom: 10px;
}
.h6-bordered-bottom{
    border-bottom: #ccc solid 1px;
}
.money-info{
    text-align: right;
}
.reputation-grade{
    height: 5px;
    width: 10px;
    margin-right: 2px;
    float: left;
    opacity: 0.2;
}
.reputation-red{
    background-color: red;
}
.reputation-orange{
    background-color: orange;
}
.reputation-yellow{
    background-color: yellow;
}
.reputation-light-green{
    background-color: lightgreen;
}
.reputation-green{
    background-color: green;
}
.opacity-full{
    opacity: 1.0;
}
.opacity-25 {
    opacity: 0.25;
}
.opacity-50 {
    opacity: 0.5;
}
.reputation-grid{
    margin-top: 10px;
}
.dropdown-menu-right{
    width: 400px;
    max-height: 500px;
    overflow-y: auto;
}
.dropdown-menu-right-compacted{
    width: 250px;
    max-height: 500px;
    overflow: hidden;
}
.container-list{
    padding: 0 5px;
    margin-top: 20px;
}
.container-list div .form-control {
    margin-bottom: none;
}
ul.pagination {
    margin: 10px 0 10px 0;
}
ul.pagination > li.page-item a {
    margin-left: 5px;
    border: none;
    //box-shadow: 3px 3px 3px #ddd;
}
.page-item.disabled .page-link {
    color: #73818f;
    pointer-events: none;
    cursor: auto;
    background-color: #ffffff;
    border-color: #c8ced3;
    border-radius: 4px;
}
.page-item.active .page-link {
    z-index: 1;
    color: #ffffff !important;
    background-color: #2fb15d;
    border-color: #2fb15d;
    border-radius: 3px;
    margin-top: 1px;
}
.page-link {
    color: #666666 !important;
    border: solid 1px #e4e7ea !important;
    border-radius: 4px;
}
.page-link:hover {
    border-radius: 4px;
    color: #666666;
    font-weight: bold;
    box-shadow: 2px 2px 2px #f4f4f4;
}
.money-value {
    text-align: right;
    margin-bottom: 0px;
    display: block;
}
.money-text {
    text-align: right;
}
.date-text {
    text-align: center;
}
.stripped-row {
    padding: 20px 10px 20px 0;
}
div.stripped-row:nth-of-type(odd) {
    background-color: #f2f3f8 ;
}
div.stripped-row:nth-of-type(even) {
    background-color: #ffffff
}
.stripped-row-compacted {
    //padding: 10px 0;
    line-height: 30px;
    font-size: 13px;
    border-bottom-color: #f2f3f8;
    border-top-color: #ffffff;
}
/*.stripped-row-compacted > div.row div {
    padding: 10px;
}
.stripped-row-compacted > div.row div:last-child {
    padding: 0
}
.stripped-row-compacted > div > div.row p {
    margin-bottom: 0px;
}
div.stripped-row-compacted:nth-of-type(odd) {
    background-color: #ffffff ;
}
div.stripped-row-compacted:nth-of-type(even) {
    background-color: #f2f3f8 ;
}*/
.btn-right{
    float: right;
}
.order-detail {
    padding-top: 20px;
}
.order-detail img{
    margin-left: 20px;
}
/* width */
::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #ffffff;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #cccccc;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #999999;
  }
  ::-webkit-scrollbar{
    height: 4px;
    width: 4px;
    background: #ffffff;
  }
  ::-webkit-scrollbar-thumb:horizontal{
        background: #999999;
        border-radius: 10px;
  }

  div.card-body table thead tr th,
  div.card-footer table thead tr th {
    border-bottom-color: #f2f3f8;
    border-top-color: #ffffff;
    background-color: #ffffff;
    color: #cccccc;
    font-size: 11px;
  }
  div.card-body table tbody tr td,
  div.card-footer table tbody tr td {
    border-color: #f2f3f8;
  }
  div.card-body table.table-detail thead tr th,
  div.card-footer table.table-detail thead tr th {
    border-bottom-color: #e4e6f1;
    border-top-color: #f2f3f8;
    background-color: #f2f3f8;
    color: #c6c7d0;
    font-size: 11px;
  }
  div.card-body table.table-detail tbody tr td,
  div.card-footer table.table-detail tbody tr td {
    border-color: #e4e6f1;
  }
  table.table tr:hover {
    background-color: #f9fafd;
  }
  table.table-detail tr:hover {
    background-color: #e4e6f1;
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #f2f3f8;
  }
  .table-header {
    background-color: #f2f3f8;
    padding: 12px 0;
    //margin-bottom: 2px;
    font-size: 12px;
  }
  .user-name {
      color: #999999;
      font-weight: bold;

  }
  .help-block {
      color: #999999
  }
  .btn-success:hover {
    color: #ffffff;
    background-color: #00ff5a;
    border-color: #00ff5a;
  }
  .btn-register {
      height: 50px;
  }
.box-button-right {
    text-align: right;
    //padding-right: 10px !important;
}
.box-button-center {
    text-align: center;
}
.row-item-compacted {
    border-bottom: solid 1px #f2f3f8;
    padding: 10px 0 0 0;
}
.row-item-compacted:hover {
    background-color: #f9fafd;
}
.col-title {
    line-height: 34px;
}
.card-header-white {
    background-color: #ffffff !important;
}
.sidebar .nav-dropdown.open {
    background: #f2f3f8;//#dddfe8;
}
.sidebar .nav-dropdown.open .nav-link {
    color: #333333;
    width: 240px;
    background-color: #f0f3f5;
    border-right: solid 1px #dddfe8;
}
.sidebar .nav-dropdown.open .nav-link .nav-icon {
    color: #999999;
}
.card-row {
    margin-bottom: 15px;
}
.card-row:hover {
    box-shadow: 3px 3px 7px #dddfe8;
    //background-color: #f4f4f4 !important;

    /*
    .card-header {
        box-shadow: 3px 3px 7px #dddfe8;
        //background-color: #f4f4f4 !important;
    }
    */
}
.card-header-list {
    margin-bottom: 15px;
}
.menu-group-header.open a.nav-dropdown-toggle:hover {
    background-color: #dddfe8;
}
.menu-group-header.open a.nav-dropdown-toggle {
    background-color: #dddfe8;//#cbcdd6;
}
.menu-group-header a.nav-dropdown-toggle {
    font-weight: bold;
}
//#dddfe8

.order-item-property-box {
    padding-left: 20px;
}
.order-item-property-box p {
    margin-bottom: 0;
}
.badge-warning {
    color: #ffffff;
    background-color: #ffc107;
}
.badge-outline-primary {
    color: #00a7da;
    border: solid 1px #00a7da;
    background-color: #ffffff !important;
}
.badge-outline-secondary {
    color: #666666;
    border: solid 1px #dddddd;
    background-color: #ffffff !important;
}
.badge-outline-danger {
    color: #f84e4e;
    border: solid 1px #f84e4e;
    background-color: #ffffff !important;
}
.badge-pill-md {
    padding: 7px 10px;
    border-radius: 25px;
}

.row-item {
    margin-top: 10px;
    padding-top: 10px;
    color: #666666;
}
.btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #2fb15d;
    border-color: #2fb15d;
}
.btn-border {
    padding: 15px 30px;
    background-color: #ffffff;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 18px;
    color: #666666;
    border-left: solid 3px #dddfe8;
}
.btn-border-success {
    padding: 7px 20px;
    background-color: #ffffff;
    border-radius: 5px;
    margin-bottom: 5px;
    font-size: 14px;
    color: #666666;
    border-left: solid 3px #2fb15d;
    border-top: solid 1px #dddfe8;
    border-bottom: solid 1px #dddfe8;
    border-right: solid 1px #dddfe8;
    cursor: pointer;
}
.btn-border-warning {
    padding: 7px 20px;
    background-color: #ffffff;
    border-radius: 5px;
    margin-bottom: 5px;
    font-size: 14px;
    color: #666666;
    border-left: solid 3px #ffc107;
    border-top: solid 1px #dddfe8;
    border-bottom: solid 1px #dddfe8;
    border-right: solid 1px #dddfe8;
    cursor: pointer;
}
.btn-border-primary {
    padding: 7px 20px;
    background-color: #ffffff;
    border-radius: 5px;
    font-size: 14px;
    color: #666666;
    border-left: solid 3px #333333;
    border-top: solid 1px #dddfe8;
    border-bottom: solid 1px #dddfe8;
    border-right: solid 1px #dddfe8;
    cursor: pointer;
}
.btn-border-success:hover, .btn-border-warning:hover, .btn-border-primary:hover,
.btn-border-success:active, .btn-border-warning:active, .btn-border-primary:active {
    box-shadow: 2px 2px 5px #dddfe8;
}
.page-title {
    margin: 0;
    color: #000000;
    font-weight: bold;
    line-height: 2;
}
.page-sub-title {
    margin: 10px 0;
    color: #999999;
    font-weight: bold;
    font-size: 1.2em;
}
.total-page {
    color: #cccccc;
    margin-top: 15px;
    display: inline-block;
}
.row-table-header {
    color: #cccccc;
    font-size: 11px;
    font-weight: bold;
    //border-bottom: 2px solid #f2f3f8;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.mb-4, .my-4 {
    margin-bottom: 1.0rem !important;
}
.col-compacted {
    padding: 0 5px 0 0;
}
.app-header .navbar-nav {
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
    color: #dddddd;
}
.modal-content {
    border-color: #ffffff !important;
    box-shadow: 0px 0px 20px #999999;
}
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #666666;
}
.sidebar-submenu a {
    //padding-left: 20px !important;
    color: #333333 !important;
}
.sidebar-submenu a app-sidebar-nav-link-content .sidebar .nav-link .nav-icon {
    color: #666666 !important;
}
.box-empty-list {
    img {
        width: 150px;
        margin: 100px 0 30px 43%;
    }
    p {
        font-size: 20px;
        text-align: center;
    }
}
.modal-payment-plan {
    max-width: 800px !important;

    ul li {
        list-style: none;
    }
}
@media (min-width: 576px) {
    .modal-payment-plan .modal-dialog {
        max-width: 800px !important;
        margin: 0rem auto !important;
    }
}
.modal-payment-plan > .modal-content {
    width: 800px !important;
}
.modal-payment-plan > .modal-content > .modal-dialog {
    margin: 0 !important;
}
.row-detail {
    margin: 20px 0;
    padding: 25px 10px;
    background-color: #f2f3f8;
    border-radius: 5px;
}
.remaining-charecters {
    color: #666666;
    margin-bottom: 10px;
    text-align: right;
    font-size: 12px;
}


.tab-filter .nav-tabs .nav-item{
  width: 15%;
}

.tab-filter .nav-tabs .nav-link.active:focus{
  border-bottom-color: #059f18;
  border-bottom: 2px solid #059f18;
  border-top: none;
  border-right: none;
  border-left: none;
}
.tab-filter .nav-tabs .nav-link{
  border: none;
}
.tab-filter .nav-tabs .nav-link.active{
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 2px solid #059f18;
  border-bottom-color: #059f18;
}
.tab-filter .tab-content{
  display: none;
}
.form-control, .form-control-lg, .form-control-sm,
.form-control:focus, .form-control-lg:focus, .form-control-sm:focus {
    color: #333333;
    font-size: 15px;
    font-weight: bold;
}
.alert-info-form {
    div.alert-info {
        background-color: #ffffff;
    }
}
.alert-secondary-form {
    div.alert-secondary {
        background-color: #ffffff;
    }
}
.alert-danger-form {
    div.alert-danger {
        color: #813838;
        background-color: #ffffff;
        //border-color: #fdd6d6;
        border: 2px solid #f5c6cb;
    }

    div.alert-info {
        color: #336573;
        background-color: #e0f3f8;
        border-color: #e0f3f8;
    }
    div.alert-warning {
      background-color: #ffffff;
      border-color: #c9960b;
  }

}
.is-invalid {
    border: solid 2px #f5c6cb !important;
}

.tab-filter .nav-tabs .nav-item{
  width: 15%;
}

.tab-filter .nav-tabs .nav-link.active:focus{
  border-bottom-color: #059f18;
  border-bottom: 2px solid #059f18;
  border-top: none;
  border-right: none;
  border-left: none;
}
.tab-filter .nav-tabs .nav-link{
  border: none;
}
.tab-filter .nav-tabs .nav-link.active{
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 2px solid #059f18;
  border-bottom-color: #059f18;
}
.tab-filter .tab-content{
  display: none;
}

.circle-status {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 18px 10px 0 20px;
    background-color: #cccccc;
}
.circle-status-positive {
    background-color: #206a5d
}
.circle-status-negative {
    background-color: #931a25
}
.circle-status-equals {
    background-color: #a2d5f2
}
.circle-status-neutral {
    background-color: #e6e6ec
}

.many-action-button {
    color: #999999;
}

.form-section-title {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    border-bottom: solid 2px #f4f4f4;
    margin: 40px 0 20px 0;
}
.form-section-subtitle {
    font-size: 14px;
    color: #999999;
    font-weight: bold;
    margin: 40px 0 20px 10px;
}
.box-color-selected {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-top: 5px;
    border-radius: 5px;
}
.box-empty {
    width: 80%;
    margin: 5% auto 10%;
    border: solid 1px #dddddd;
    color: #cccccc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;

    img {
        margin: 40px 0 20px;
        width: 170px;
    }
    p {
        font-size: 17px;
    }
    i {
        font-size: 30px;
        margin: 10px;
    }
}
.input-loading {
    background: url(../assets/img/loading.gif) no-repeat scroll;
    background-size: 30px;
    background-position: 99% 7px;
}
.icon-suport {
    height: 32px;
    opacity: 0.4;
}
.tag-user-role {
    display: inline-block;
    font-size: 10px;
    color: #999;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #f4f4f4;
    padding: 0 10px;
    margin-left: 20px;
}
.icon-record-status {
    background-color: #f4f4f4;
    width: 25px;
    height: 25px;
    border-radius: 10px;
    font-size: 11px;
    padding: 5px 8px;
    display: inline;
}
.dropdown-item i {
    color: #333333;
}
.img-item-list {
    border: solid 1px #dddddd;
    border-radius: 5px;
}
#count-notifications {
    font-size: 12px;
}
.fa-info-circle {
    color: #cccccc;
}
label {
    color: #666666;
}

.card {
    border-radius: 18px;
}
.card-header {
    border-top-left-radius: 7px !important;
    border-top-right-radius: 7px !important;
}
.card-footer {
    border-bottom-left-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
}
.padding-r-0{
  padding-right: 0;
}
.padding-l-0{
  padding-left: 0;
}
